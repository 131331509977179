import React from "react"
// import LocalVideoOne from "../images/gallery/KimbleVideos/POPUP_ACTION_MOVIE_1080p.mp4"

export default function EmbedVideo(props) {

  return (
    <video 
    controls 
    disablePictureInPicture={true} controlsList="nodownload"
    allowFullScreen={false}
    poster={props.poster} 
    loop={props.loop} 
    width={props.width} 
    height={props.height}>
      <track default src="/videoTrack.vtt" />
      <source src={props.src} type="video/mp4" />
    </video>
  )
}
