import React, { useState, useEffect } from "react"
import {graphql, Link} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import EmbedVideo from '../../../components/EmbedVideo'
import Helmet from '../../../components/Helmet';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

import BarEnd from '../../../images/svg/barend.inline.svg'
import HollowCube from '../../../images/svg/HOLLOW_cube.inline.svg'
import OliveGreenCube from '../../../images/svg/OLIVE_GREEN_cube.inline.svg'
import '../../../styles/BlogArticleTemplateStyles.scss'

import DRAnimGif from '../../../images/content/blog/dr_webglobeanim_web.gif'

const BlogArticleTemplate = ({ data }) => {
  // console.log("DATA on BlogJson.slug: ", data);

  const {
    // jsonId,
    title,
    // subTitle,
    alt,
    slug,
    thumbnail,
    shortDescription,
    htmlCopy,
    tags,
    blogImgs,
    blogSideImgs,
    videos
  } = data.blogJson;

  const thumbImg = getImage(thumbnail)

  function makeHTML(htmlContent) { return {__html: htmlContent}; }
  
  const [deviantRhythms, setDeviantRhythms] = useState(false)
  const [overlandGallery, setOverlandGallery] = useState(false)

  function DRAnim() {
    return (
      <img key="bar" src={DRAnimGif} alt="looping animation of small satellite rotating around a stylized digital globe" />
    )
  }
  function OverlandGalleryLinks() {
    return (
      <div key={"foo"} className="gallery-links">
        <h4 className="h3">
          <Link to="/blog/gallery/kimble-visualization-gallery">View 3D Vizualization Gallery</Link>
        </h4>
        <h4 className="h3">
          <Link to="/blog/gallery/kimble-build-gallery">View Build Gallery</Link>
        </h4>
      </div>
    )
  }
 

useEffect(() => {

  if(slug ===  "tradition-in-world-of-change" ) {
    setDeviantRhythms(true)
  } else if (slug ===  "expedition-overlanding-tiny-home" ) {
    setOverlandGallery(true)
  } 
  
}, [])



  return (
    <>
		<Helmet title={`Blog Article: ${title}`} />
		<Header subNav={true} />

		<main id="blog-article">
      <div className="container">
        <section className="section container">
          
          <div className="content" id="main-content">
            
            <section className="blog">
            <div className="blog-header">
              
              <div className="top-title">
                  <div>
                    <OliveGreenCube aria-hidden="true" />
                    <h1 className="h1"><span>Blog Article</span></h1>
                  </div>
                  
              </div>
              <div className="global-tags tags-header" role="group" aria-label="View similarly tagged content">
                <h2 className="h1"><span>Tags</span></h2>
                <div className="blog-tags">
                
                {tags.map((tag, index)=>{
                    return (
                        <Link key={"tags-"+index} to={`/blog/tag/${tag.slug}`}>
                              <abbr title={tag.definition} className={tag.class}>{tag.name}</abbr>
                        </Link>
                    )
                })}

                </div>
              </div>
            </div>
          
            <div className="module-blog-unit">
              <div className="thumb-wrap" aria-hidden="true">
                <GatsbyImage image={thumbImg} alt={alt} />
              </div>
                                    
                <div className="blog-unit">
                    <div className="blog-content">
                        <div className="blog-description">
                          <div className="blog-detail" role="region" aria-label="Article content">
                              <h3 className="h3">{title}</h3>
                              <p>{shortDescription}</p>
                              <div dangerouslySetInnerHTML={makeHTML(htmlCopy)} />
                              
                              {overlandGallery ? <OverlandGalleryLinks /> : ""}
                          </div>
                        
                          <div className={`blog-videos ${deviantRhythms ? "dr-bak": ""} role="region" aria-label="article videos and images"`}>
                           {deviantRhythms ? <DRAnim /> : ""}

                           {blogSideImgs ? 
                           
                            blogSideImgs.map((img, index) => {
                              const sideImage = getImage(img.src)
                              // console.log("blogSideImg: ", sideImage)
                      
                                return (
                                  <div key={"img-"+index} className={`area blog-side-image-area-${index}`} aria-label={`Image ${index+1} of ${blogSideImgs.length}`}>
                                    <GatsbyImage image={sideImage} alt={alt} className="side-img" />
                                  </div>
                                )
                            })
                            
                           
                           : ""}
                            
                            { videos.map((video, index) => {
                                if (video !== null || video !== undefined) {
                                  return (
                                    <>
                                    {video.title && <div key={"head-"+index}><h5 className="h4">{video.title}</h5><p>{video.description}</p></div>}
                                    <div key={"video-"+video.jsonId} className={`area video-area-${index}`} aria-label={`Video ${index+1} of ${videos.length}`}>
                                        <EmbedVideo 
                                          poster={video.poster} 
                                          src={video.src}
                                          width="100%" 
                                          loop={video.loop}
                                        />
                                    </div>
                                    </>
                                   )
                                  } // if(video)
                                  return null
                                })
                              }
                          </div>
                        </div>
                      
                        <div id={`${slug}-assets`} className="blog-assets" role="region" aria-label="Photos and graphics related to this article">
                          <span className="sr-only" tabIndex="0">You may choose to <a href="#main-content">skip this section and return to main content.</a></span>
                          
                          { blogImgs ?
                              
                            blogImgs.map((image, index) => {
                              const currentImage = getImage(image.src)
                              // console.log("blogImg: ", currentImage)
                            
                              return (
                                <div key={"blogimg-"+index} className={`area area-${index}`} aria-label={`Image ${index+1} of ${blogImgs.length}`}>
                                  <GatsbyImage image={currentImage} alt={alt} className="area-img" />
                                </div>
                              )
                            })
                          : "" }
                        </div>

                    </div>
                </div>
            </div>



            <div className="bot-title">
                <HollowCube aria-hidden="true" />
                <div  className="more-link">
                    <Link to="/blog"><span className="sr-only">View </span>All Blog<span> listings</span> <BarEnd aria-hidden="true" /></Link>  
                </div>
            </div>



            </section>
          </div>
        </section>
      </div>
		</main>
		<Footer />
	</>
  )
}

export const query = graphql`
query blogArticle($slug: String) {
  blogJson(slug: {eq: $slug}) {
    slug
    jsonId
    alt
    htmlCopy
    shortDescription
    tags {
      class
      definition
      definitionDescription
      name
      slug
    }
    thumbnail {
      absolutePath
      childImageSharp {
        gatsbyImageData(layout: FIXED, placeholder: TRACED_SVG, width: 200)
      }
    }
    title
    blogImgs {
      src {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width:600, quality: 95)
        }
      }
    }
    videos {
      jsonId
      src
      title
      description
      poster
      loop
    }
    blogSideImgs {
      src {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED, width:600, quality: 95)
        }
      }
    }
  }
}

`
export default BlogArticleTemplate
